import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ProductsPage.css";
import { collection, query, getDocs} from 'firebase/firestore';
import { firestore } from '../config/firebaseConfig';

import useFetchAllProducts from "../hooks/useFetchAllProducts";

export function ProductsPage() {
  const { allProducts } = useFetchAllProducts();
  const [currentCol, setCurrentCol] = useState('All');
  const [collectionItems, setCollectionItems] = useState<{ name: String, thumbnailUrl: String }[]>([]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  });
  useEffect(() => {
    const q = query(collection(firestore, 'collection', currentCol, 'products'));
    getDocs(q)
      .then((qs) => setCollectionItems(qs.docs.map((d) => ({ name: d.data().name, thumbnailUrl: d.data().thumbnailUrl }))))
      .catch(e => console.log(e))
  }, [currentCol]);

  return (
    <section id="content">
      <div className="content-wrap">
        <div className="container">
          <h1 className="heading">
            Kitchen
            <br />
            Cabinets
          </h1>
          <div className="series-nav">
            <button
              className="series-nav_link"
              onClick={() => setCurrentCol('All')}
            >
              All
            </button>
            <button
              className="series-nav_link"
              onClick={() => setCurrentCol("Thermofoil Collection")}
            >
              Thermofoil Collection
            </button>
            <button
              className="series-nav_link"
              onClick={() => setCurrentCol("Melamine Collection")}
            >
              Melamine Collection
            </button>
          </div>

          <section className="product-area product-grid-list-area mt-4">
            <div className="container">
              <div className="col-md-12 col-lg-12 col-sm-6">
                <div className="product-body-wrap">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-grid"
                      role="tabpanel"
                      aria-labelledby="nav-grid-tab"
                    >
                      <div className="row ">
                        {collectionItems && currentCol !== 'All' &&
                          collectionItems.map((product, index: number) => {
                            return (
                              <div
                                key={index}

                                className="col-md-3 door-card center  "
                              >
                                <Link to="/product/1" state={{ product: product, type: currentCol }}>
                                  {" "}
                                  <img
                                    className="w-100"
                                    alt="This is the product"
                                    src={product.thumbnailUrl?.toString()}
                                  />
                                </Link>
                                <div className="door-card_title">
                                  {" "}
                                  <span className="series">{product.name}</span>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {currentCol === 'All' && <div className="row ">
                        {allProducts.map((product, index: number) => {
                          return (
                            <div
                              key={index}
                              className="col-md-3 door-card center  "
                            >
                              <Link to="/product/1" state={{ product: product, type: product.type }}>
                                {" "}
                                <img
                                  className="w-100"
                                  alt="This is the product"
                                  src={product.thumbnailUrl?.toString()}
                                />
                              </Link>
                              <div className="door-card_title">
                                {" "}
                                <span className="series">{product.name}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
}
