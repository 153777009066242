import React, { useEffect } from "react";
import "./Inspiration.css";

import useFetchInspirationData from "../hooks/useFetchInspirationData";
import { Link } from "react-router-dom";
export function Inspiration() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  let inspirationData = useFetchInspirationData();
  console.log(inspirationData, 'inspirationData');
  let { allProducts, collections } = inspirationData;

  let newCollections = collections.map((c) => {
    const p = allProducts.find((x) => x.type === c.name)
    return { ...c, imageSrc: p?.imageSrc }
  })
  return (

    <>
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <h1 className="heading">
              HORIZON MILLWORKS
              <br />
              GALLERY
            </h1>

            <div className="gallery">
              {
                newCollections.map((category, index) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}
                    className="insp-pics" >
                    <Link
                      to={`/inspiration/${index + 1}`}
                      state={{ type: category.name }}
                      style={{
                        backgroundImage: `url(${category?.imageSrc})`,
                        height: '300px',
                        width: '100%',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover'
                      }}
                    />
                    <div className="gallery-post" style={{ width: '100%' }}>
                      <div className="title">{category?.name}</div>
                      <div className="arrow"></div>
                    </div>
                  </div>
                )
                )
              }

            </div>
          </div>
        </div>
      </section>
    </>
  );
}
