import React from "react";

const Form = () => {
  return (
    <div
      className="contact_other wow"
      style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)", marginBottom: '0' }}
    >
      <h1 className="fancy-heading">
        Contact
        <br />
        Us
      </h1>
      <form className="rendered">
        <p className="form-page">
          Thank you for your interest in partnering with Horizon Millwork Solutions.
          Please leave your information below and one of our specialized representatives
          will reach out to you and assist you with your project needs.
        </p>
        <section className="form-section">
          <div className="form-section_fields-container">
            <div className="form-field form-field--text">
              <label className="label " htmlFor="name">
                Name
              </label>
              <input
                type="text"
                className="form-field-input"
                id="name"
                name="Name"
                autoComplete="name"
                required
              />
            </div>
            <div className="form-field form-field--text">
              <label className="label " htmlFor="email">
                Email
              </label>
              <input
                type="email"
                className="form-field-input"
                id="email"
                required
              />
            </div>
            <div className="form-field form-field--text">
              <label className="label " htmlFor="phone">
                Phone
              </label>
              <input
                type="text"
                className="form-field-input"
                id="phone"
                required
              />
            </div>
            <div className="form-field form-field--text">
              <label className="label " htmlFor="website">
                Zip Code
              </label>
              <input
                type="text"
                className="form-field-input"
                name="Zip"
                required
              />
            </div>
            <div className="form-textarea ">
              <label className="label ">Message</label>
              <textarea
                className="form-field-input"
                name="Message"
                cols={50}
                rows={5}
              />
            </div>
          </div>

          <div className="d-grid gap-2 col-12 mx-auto">
            <button className="btnn btn-primary" type="button">
              Submit
            </button>
          </div>
        </section>
      </form>

      <div className="hours">
        <div className="fancy-heading">
          Hours of
          <br />
          Operation
        </div>
        <div className="hours">
          Monday-Friday 8-5 &nbsp;&nbsp; (201) 432.5667
        </div>
      </div>
    </div>
  );
};

export default Form;