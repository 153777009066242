import React, { useEffect } from "react";
import "./Inspiration.css";
import useFetchInspirationData from "../hooks/useFetchInspirationData";
import { useLocation } from "react-router-dom";
export function SingleInspiration() {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    });
    let inspirationData = useFetchInspirationData();
    let { allProducts } = inspirationData;
    console.log(allProducts, 'allProducts');

    let { state }: any = useLocation();
    console.log(state, 'state')
    return (
        <>
            <section id="content">
                <div className="content-wrap">
                    <div className="container">
                        <h1 className="heading">
                            {state.type}
                        </h1>

                        <div className="gallery">

                            {allProducts.filter(product => product.type === state.type)?.map((product, index) => {
                                return (
                                    <div className="single-insp-pics" key={index}
                                        style={{
                                            backgroundImage: `url(${product.imageSrc})`,
                                            height: '180px',
                                            // width: '200px',
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover'
                                        }}
                                    >
                                        {/* <img
                                            src={product.imageSrc}
                                            // style={{
                                            //   width: `${item.width}`,
                                            //   height: `${item.height}`,
                                            // }}
                                            style={{ objectFit: 'cover'}}
                                            alt=""
                                        /> */}

                                    </div>
                                );
                            })}
                        </div>


                        <div style={{ border: '2px solid white', marginTop: '5rem' }}></div>
                        {/* <div className="center-button">
              <div
                className="button button-animated"
                style={{ display: "block" }}
              >
                <span className="middle">Load More</span>
              </div>
            </div> */}
                                    </div>
                </div>
                    </section>
                </>
                );
}