import { useLocation, useNavigate } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { firestore } from "../config/firebaseConfig";
import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./SignalProduct.css";
import Form from "./Form";
import useFetchAllProducts from "../hooks/useFetchAllProducts";
export default function SignalProduct() {
  const images = [
    "https://images.prismic.io/fabuwoodtest/2d3df5e2-c26a-4f5d-9556-ba8e3556f4b1_Luna_Kona_NEW.png?auto=compress,format",

    "https://images.prismic.io/fabuwoodtest/1bbde074-5648-4c1e-bc53-c6a2a0c19199_fusion_stone_new_pn.png?auto=compress,format",

    "https://images.prismic.io/fabuwoodtest/15353ca3-2ec5-467c-b02e-9509f7e162a7_galaxy_frost_092420.jpg?auto=compress,format",

    "https://images.prismic.io/fabuwoodtest/b1251f49-38b8-49f7-925f-7f70af3583da_Galaxy_indigo_new_pn.png?auto=compress,format",

    "https://images.prismic.io/fabuwoodtest/b1251f49-38b8-49f7-925f-7f70af3583da_Galaxy_indigo_new_pn.png?auto=compress,format",

    "https://images.prismic.io/fabuwoodtest/b1251f49-38b8-49f7-925f-7f70af3583da_Galaxy_indigo_new_pn.png?auto=compress,format",

    "https://images.prismic.io/fabuwoodtest/b1251f49-38b8-49f7-925f-7f70af3583da_Galaxy_indigo_new_pn.png?auto=compress,format",

    "https://images.prismic.io/fabuwoodtest/b1251f49-38b8-49f7-925f-7f70af3583da_Galaxy_indigo_new_pn.png?auto=compress,format",
  ];
  const { allProducts } = useFetchAllProducts();
  console.log(allProducts, 'products');
  const [selectedimg, setSelectedimg] = useState(images[0]);
  const [data, setData] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let navigate = useNavigate();
  let state = useLocation().state as { type: string; product: any };
  const [open, setOpen] = useState(false);
  console.log(state, 'state');
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [collectionItems, setCollectionItems] = useState<{ name: String, thumbnailUrl: String }[]>([]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  });
  useEffect(() => {
    const q = query(collection(firestore, 'collection', state.type, 'products'));
    getDocs(q)
      .then((qs) => setCollectionItems(qs.docs.map((d) => ({ name: d.data().name, thumbnailUrl: d.data().thumbnailUrl }))))
      .catch(e => console.log(e))
  }, [state.type]);

  useEffect(() => {
    // for individual data fetching from firebase

    const docRef = doc(firestore, "collection", state?.type);

    getDoc(docRef)
      .then((docSnap) => setData(docSnap.exists() ? docSnap.data() : {}))
      .catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // });
  console.log(collectionItems, 'collectionItems')

  let [clickImage, setClickImage] = useState(false);
  let handleEvent = (product) => {
    setClickImage(true);
    setSelectedimg(product);
  };
  return (
    <div>
      <>
        <section className=" container mt-5">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 ">
              <div className="container">
                <img
                  src={
                    clickImage
                      ? selectedimg
                      : state.product.thumbnailUrl.toString()
                  }
                  alt="selected"
                  className="selected"
                />
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <div className="gallery-shop">
                <div className="btn_heading ">Semi-Custom</div>
                <div className="clearfix line-border prodcut-name-details">
                  <div className="pull-left">
                    <span className="prodcut-name BodoniSvtyTwoITCTTBook">
                      {data.name}
                    </span>
                    <br />
                    <span className="prodcu-color"> {state.product.name} </span>
                  </div>
                </div>
                <div className=" row color-icon clearfix single-product-row">
                  {collectionItems &&
                    collectionItems.slice(0, 8).map((product, index) => {
                      return (
                        <div className=" col-md-3 ">
                          <button
                            className="colorselected"
                            style={{ margin: "0px!important" }}
                          >
                            <img
                              style={{
                                marginTop: "6px",
                                width: "100%",
                                maxWidth: "100%",
                              }}
                              key={index}
                              src={product.thumbnailUrl.toString()}
                              onClick={() => handleEvent(product.thumbnailUrl.toString())}
                              alt=""
                            />
                          </button>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="productHeadSamples">
                <img
                  className="img"
                  src="https://homedecoratorscabinetry.homedepot.com/images/icon_10_white.png"
                  alt=""
                ></img>{" "}
                <span className="spanTitle" onClick={onOpenModal}>Order Door Sample</span>
              </div>
              <div className="productHeadButtons">
                <div
                  className="product-euro form-group"
                  style={{
                    display: "inline-block",
                    float: "left",
                    paddingTop: 15,
                    width: "100%",
                    marginBottom: 0,
                  }}
                >
                  <button
                    className="btn btn-orange-lg"
                    type="button"
                    style={{
                      fontSize: 20,
                      fontFamily: "MontserratBold",
                      height: 65,
                    }}
                    onClick={onOpenModal}
                  >
                    <span className="t16">Contact us</span>
                    <i className="fas fa-angle-down" aria-hidden="true" />
                  </button>
                  <Modal open={open} onClose={onCloseModal}>
                    <div
                      style={{ border: "2px solid", marginTop: "15rem" }}
                    ></div>
                    <div style={{ height: "100%" }}>
                      <Form />
                    </div>
                  </Modal>

                </div>
              </div>
            </div>

            {state.product.projectSrc && (
              <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                <div>
                  <img
                    src={state.product.projectSrc}
                    alt="Los Angeles"
                    className="d-block"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            )}
          </div>
        </section>

        <section className=" container cont">
          <div className="divider  clearfix">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 shop-description">
                <h4 className="darkred">Features</h4>
                <ul className="shop-list">
                  <li>Ships in 7-10 Days</li>
                  <li>All Plywood Cabinet Construction</li>
                  <li>Soft Close Doors and Drawers</li>
                  <li>Solid Hardwood Dovetail Drawers</li>
                  <li>KCMA certified and CARB II compliant</li>
                  <li>Lifetime Limited Warranty</li>
                </ul>
                &nbsp;
              </div>
              <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 shop-description">
                <h4 className="darkred">Description</h4>
                <p>
                  {data.description}
                  <br />
                  <br />
                </p>
                &nbsp;
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 hidden-xs mt-5">
                <div className="row">
                  <div className="col-lg-12 col-sm-4 col-md-4 ">
                    <div className="divicon">
                      <img
                        src="https://homedecoratorscabinetry.homedepot.com/images/icon_5.png"
                        alt=""
                      />
                      <p style={{ paddingTop: " 10px" }}>
                        <span>Free Design Service</span>
                        <br />
                        <span className="spanitalic">
                          (Within 24 to 48 hrs)
                        </span>
                      </p>
                      <div className="pup-a">
                        <a
                          className="acommonprompt"
                          href="/"
                          rel="popover"
                          data-content="<div style='' class='right-up md-up darkred'><strong>Free Design Service</strong> <br/>
Our Kitchen Design Experts will provide you a FREE Computer design in 24-48 Hours.</div>"
                          data-original-title
                        >
                          <i
                            className="fas fa-question-circle "
                            style={{ margin: "10px 10px 0 0" }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-4 col-md-4  but">
                    <div className="divicon">
                      <img
                        src="https://homedecoratorscabinetry.homedepot.com/images/icon_6.png"
                        alt=""
                      />
                      <p style={{ paddingTop: " 10px" }}>
                        <span>All Plywood Construction</span>
                        <br />
                        <span className="spanitalic">(Free Upgrade)</span>
                      </p>
                      <div className="pup-a">
                        <a
                          className="acommonprompt"
                          href="/"
                          rel="popover"
                          data-content="<div style='' class='right-up md-up darkred'><strong>All Plywood Construction</strong> <br/>
All Home Decorators Collection Kitchen and Bath Cabinets feature premium all plywood construction.</div>"
                          data-original-title
                        >
                          <i
                            className="fas fa-question-circle "
                            style={{ margin: "10px 10px 0 0" }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </div >
  );
}
