export function Footer() {
	return <footer id="footer" className="dark">

		<div id="copyrights">
			<div className="container">

				<div className="row col-mb-30">

					<div className="col-md-6 text-center text-md-start">
						Copyrights &copy; 2022 All Rights Reserved by Horizon Millworks Inc.<br />
						<div className="copyright-links"><a href="terms.html">Terms
							of Use</a> / <a href="privacy.html">Privacy Policy</a></div>
					</div>

					<div className="col-md-6 text-center text-md-end">

						<div className="clear"></div>

						<i className="icon-envelope2"></i> info@horizonmillworksolutions.com <span className="middot">&middot;</span> <i className="icon-headphones"></i> (201) 432.5667
					</div>

				</div>

			</div>
		</div>
	</footer>
}
