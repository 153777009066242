import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
//The queries for All categories are written here. 
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../config/firebaseConfig';

export default function useFetchInspirationData() {
  const [collections, setCollections] = React.useState<any>([]);
  const [allProducts, setAllProducts] = React.useState<any>([]);
  React.useEffect(() => {
    const collectionsRef = collection(firestore,'inspirations');
    getDocs(collectionsRef)
    .then((docSnap)=> setCollections(docSnap.docs.map((d)=> ({ ...d.data(), id: d.id }))))
    .catch((e) => console.log({e}));
  }, []);

  React.useEffect(() => {
    const products: any = [];
    collections.forEach(async (col: {name: string, id: string}) => {
      await getDocs(collection(firestore, 'inspirations', col.id, 'photos'))
        .then((qs) => {

          products.push(
            ...qs.docs.map((doc) => ({...doc.data(), type:col.name}))
          )
        }
        
        );
          
      setAllProducts(products);
    });
  }, [collections]);
  
  return { allProducts, collections };
}