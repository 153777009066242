import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Metadata } from '../models/Metadata';
import { Specification } from '../models/Specification';
import { useStore } from '../stores';

import "./ProductDetailPage.css"

export function ProductDetailPage() {
  const { collectionId, productId } = useParams();

  const loadCurrentProduct = useStore((s) => s.loadCurrentProduct);
  const currentProduct = useStore((s) => s.currentProduct);
  //const isLoadingCurrentProduct = useStore((s) => s.isLoadingCurrentProduct);

  const [selectedMetadata,setSelectedMetadata] = useState<Metadata | undefined>(undefined)

  useEffect(() => {
      loadCurrentProduct(collectionId!, productId!).then((p) => {
          setSelectedMetadata(p.metadata[0])
      })
  }, [collectionId,loadCurrentProduct,productId]);

    return <section id="content">
        <div className="content-wrap">
            <div className="container clearfix">
                <div className="single-product">
                    <div className="product">
                        <div className="row gutter-40">
                            <div className="col-md-6">
                                <div className="product-image">
                                    <img alt={`Header for ${currentProduct?.name}`} src={selectedMetadata?.imgUrl} />
                                </div>
                            </div>

                            <div className="col-md-6 product-desc">

                            <div className="product-title"><h3>{currentProduct?.name}</h3></div>

                                <div className="line"></div>

                                <p>{selectedMetadata?.description}</p>

                                <ul className="list-group list-group-flush">
                                    {currentProduct?.specifications?.map(spec => <SpecificationCard spec={spec} />)}
                                </ul>

                                <div className="line"></div>

                                <div className="metadata-container">
                                {currentProduct?.metadata?.map(meta =>{
                                    console.log({meta,selectedMetadata,selected: meta.id === selectedMetadata?.id})
                                    return <MetadataCard onSelect={() => {
                                    setSelectedMetadata(meta)
                                }} selected={selectedMetadata?.id === meta.id} metadata={meta} />})}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

interface MetdataCardProps {
    metadata: Metadata;
    selected: boolean;
    onSelect: () => void;
}

export function MetadataCard({metadata,selected,onSelect}: MetdataCardProps) {
    return <div onClick={onSelect} className="metadata">
    <img className="metadata-img" alt={`Metadata: ${metadata.description}`} src={metadata.imgUrl} />
    <p>{metadata.description}</p>
    <p>{selected? "selected": "asdf"}</p>
    </div>
}
interface SpecificationCardProps {
    spec: Specification;
}

export function SpecificationCard({spec}: SpecificationCardProps) {
    return <>
    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
        <span className="text-muted">{spec.description1}:</span><span className="text-dark fw-semibold">{spec.description2}</span>
    </li>
    </>
}
