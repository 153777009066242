import React from 'react';
import { collection, getDocs, query } from 'firebase/firestore';
import { firestore } from '../config/firebaseConfig';

export default function useFetchAllProducts() {
  const [allProducts, setAllProducts] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const fetchData = async () => {
      const CACHE_DURATION = 1000 * 60 * 60 * 24; // Cache duration: 24 hours
      const cachedData = localStorage.getItem('allProducts');
      const cachedTime = localStorage.getItem('allProductsTime');

      if (cachedData && cachedTime && (Date.now() - parseInt(cachedTime)) < CACHE_DURATION) {
        // Use cached data
        setAllProducts(JSON.parse(cachedData));
        setLoading(false);
      } else {
        // Fetch new data
        try {
          const collectionsRef = collection(firestore, 'collection');
          const collectionsSnapshot = await getDocs(collectionsRef);
          const collections = collectionsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

          const productPromises = collections.map(async (col) => {
            const q = query(collection(firestore, 'collection', col.id, 'products'));
            const qs = await getDocs(q);
            return qs.docs.map((doc) => ({ ...doc.data(), type: col.id }));
          });

          const productsArrays = await Promise.all(productPromises);
          const products = productsArrays.flat();

          setAllProducts(products);
          localStorage.setItem('allProducts', JSON.stringify(products));
          localStorage.setItem('allProductsTime', Date.now().toString());
        } catch (error) {
          console.error('Error fetching products:', error);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { allProducts, loading };
}
