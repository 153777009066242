import { initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDfzCypeNJsxgagBHYV05xLDI7fAKl5r5g",
  authDomain: "horizon-millwork.firebaseapp.com",
  projectId: "horizon-millwork",
  storageBucket: "horizon-millwork.appspot.com",
  messagingSenderId: "874261408968",
  appId: "1:874261408968:web:c08b7c0f5ddf1e539349fd",
  measurementId: "G-P0MX83YP2P"
};

export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

// Emulators
if (window.location.hostname === 'localhost') {
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
}
