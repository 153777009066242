import React, { useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SlideImg from "../assets/images/Slideshow.png";
import "aos/dist/aos.css";
import "./home.css";
import { Link } from "react-router-dom";
import Carousel from "./Carousel";
import Pagination from "./Pagination";
import NewProducts from "./NewProducts";
const AOS = require("aos");

export function HomePage() {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 1000,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeSlide, setActiveSlide] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (


    <div className="home-container js-scroll-counter scroll-counter w-100">
      <Carousel />
      <div className="row mx-0">
      <Link className="col-sm-6 tab-right" to="inspiration">
          <h5>INSPIRATIONS</h5>
          <p>Click me to go Inspiration Page</p>
        </Link>
        <Link className="col-sm-6 tab-left" to="products">
          <h5>PRODUCTS </h5>
          <p>Click me to go Products Page</p>
        </Link>
      </div>


      <Pagination />

      <NewProducts />


      <div className="row mx-0">
        <Link className="col-lg-6 tab-left" to="/resources">
          <h5>SPEC BOOK</h5>
        </Link>
        <Link className="col-lg-6 tab-right" to="/contact">
          <h5>CONTACT US</h5>
        </Link>
      </div>

      <div style={{ marginBottom: "150px" }}></div>
    </div>
  );
}
