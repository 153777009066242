import React from 'react'
import useFetchSliderData from '../hooks/useFetchSliderData';

const Carousel = () => {
  const sliderDocs = useFetchSliderData();
  return (
    <div
        id="carouselExampleIndicators"
        className="carousel slide carousel-container"
        data-bs-ride="true"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          />
          {/* <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          /> */}
        </div>
        <div className="carousel-inner">
          {
            sliderDocs.map(sliderData => 
              
              <div className="carousel-item active">
            <img
              src={sliderData.imageSrc}
              className="carousel-img d-block w-100"
              alt="..."
              style={{backgroundPosition:'center',backgroundSize:'cover'}}
            />
            <div className="carousel-caption   d-md-block custom-slider text-left">
              <hr className="hr" />

              <h1 >{sliderData.title}</h1>
              <p>{sliderData.description}</p>

              <div className="mt-4">
                <button type="button" className="btn  btn-blue text-white">
                  Learn More Here
                </button>
              </div>
            </div>
          </div>
              
              )
          }
          
          
          
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
  )
}

export default Carousel