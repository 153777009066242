import create from 'zustand';
import {
  getAllProducts,
  getCollectionWithProduct,
  getInspirations,
  getInspirationWIthId,
  getProductCollections,
  getProductData,
  getProductWithThumbnail,
} from './db';
import { Collection, CollectionWithProducts } from './models/Collection';
import {Inspiration} from './models/Inspiration';
import { Product } from './models/Product';

interface Store {
  collections: Collection[],
  loadCollection: () => Promise<void>,

  currentCollection: CollectionWithProducts | null,
  isLoadingCurrentCollection: boolean,
  loadCollectionWithProduct: (collectionId: string) => Promise<void>,
  loadAllProducts: () => Promise<void>

  currentProduct: Product | null,
  isLoadingCurrentProduct: boolean,
  loadCurrentProduct: (collectionId: string, productId: string) => Promise<Product>

  inspirations: Inspiration[],
  loadAllInspiration: () => Promise<void>,

  currentInspiration: Inspiration | null,
  loadCurrentInspiration: (inspId: string) => Promise<void>
}

export const useStore = create<Store>((set, store) => ({
  collections: [],
  loadCollection: async () => {
    const collections = await getProductCollections();
    set({ collections });
  },

  currentCollection: null,
  isLoadingCurrentCollection: true,
  loadCollectionWithProduct: async (collectionId: string) => {
    set({ isLoadingCurrentCollection: true });
    const collectionWithProduct = await getCollectionWithProduct(collectionId);
    set({ currentCollection: collectionWithProduct, isLoadingCurrentCollection: false });
  },
  loadAllProducts: async () => {
    set({ isLoadingCurrentCollection: true });
    const products = await getAllProducts();
    set({
      currentCollection: {
        id: 'ALL',
        name: 'ALL',
        products,
      },
      isLoadingCurrentCollection: false,
    });
  },

  currentProduct: null,
  isLoadingCurrentProduct: true,
  loadCurrentProduct: async (collectionId: string, productId: string) => {
    set({ isLoadingCurrentProduct: true });

    let currProduct = store().currentCollection?.products.find((e) => e.id === productId);

    if (!currProduct) {
      currProduct = await getProductWithThumbnail(collectionId, productId);
    }

    const prod = await getProductData(collectionId, productId);

    const currentProduct = {
      ...currProduct,
      ...prod,
    };

    set({ currentProduct, isLoadingCurrentProduct: false });
    return currentProduct
  },

  inspirations: [],
  loadAllInspiration: async () => {
      set({
          inspirations: await getInspirations()
      })
  },

  currentInspiration : null,
  loadCurrentInspiration: async (inspId: string) => {
      let currInsp = store().inspirations?.find(e => e.id === inspId)

      if(!currInsp) {
          currInsp = await getInspirationWIthId(inspId)
      }

      set({ currentInspiration:currInsp})
  }
}));
