// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { NONAME } from 'dns';
import React, { useState } from 'react'
import useFetchBannerData from '../hooks/useFetchBannerData';

const Pagination = () => {
    const data = useFetchBannerData();
    const [index, setIndex] = useState(0);
    console.log(index, 'index')

    return (
        <div className="section-2-container ">
            <div className="row mx-0 section-2-div ">
                <div
                    className="col-lg-6 col-md-12 col-sm-12 section-column-left px-0"
                    data-aos="fade-right"
                >
                    <h1>{index + 1 < 10 ? `0${index + 1}` : index + 1}</h1>
                    <p className='description'>
                        {data[index]?.description}
                    </p>
                </div>

                <div
                    className="col-lg-6 col-md-12 col-sm-12 section-column-right px-0"
                    data-aos="fade-left"
                >
                    <img
                        src={data[index]?.imageSrc}
                        className="img-fluid rounded"
                        alt="rectangle=img"
                    />
                    {/* Pagination */}
                    <nav aria-label="Page pagination-container navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <p className="page-link" onClick={() => setIndex(index === 0 ? data.length - 1 : index - 1)} aria-label="Previous">
                                    <span aria-hidden="true" >
                                        <svg width="50px" height="50px" viewBox="0 0 24 24" id="right-arrow" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(-180deg)' }}><path id="primary" d="M3,12H21m-3,3,3-3L18,9" style={{ fill: 'none', fontSize: '2rem', stroke: '#fff', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5' }}></path></svg>
                                    </span>
                                </p>
                            </li>
                            <li className="page-item">
                                <p className="page-link" onClick={() => setIndex(index === data.length - 1 ? 0 : index + 1)} aria-label="Next">
                                    <span aria-hidden="true" style={{ width: '12px', height: '12px', }}>
                                        <svg width="50px" height="50px" viewBox="0 0 24 24" id="right-arrow" xmlns="http://www.w3.org/2000/svg"><path id="primary" d="M3,12H21m-3,3,3-3L18,9" style={{ fill: 'none', fontSize: '2rem', stroke: '#fff', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5' }}></path></svg>
                                    </span>
                                </p>
                            </li>
                        </ul>
                    </nav>
                    {/* Pagination End */}
                </div>
            </div>
        </div>
    )
}

export default Pagination