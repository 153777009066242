import { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

export function Navbar() {
  let [show, setShow] = useState(false);
  console.log(show);
  return (
    <header id="header" className="full-header">
      <div id="header-wrap">
        <div className="container">
          <div className="header-row">
            <div id="logo">
              <Link
                to="/"
                // className="standard-logo"
                data-dark-logo="images/logo-dark.png"
              >
                <img src="https://firebasestorage.googleapis.com/v0/b/horizon-millwork.appspot.com/o/homepage%2Flogo%2FHMS-logo.png?alt=media&token=bcf7039d-e48d-4906-a226-71a04f330029" alt="Horizon Millworks Logo" />
              </Link>
              {/* <Link
                to="/"
                className="retina-logo"
                data-dark-logo="images/logo-dark@2x.png"
              >
                <img src="https://firebasestorage.googleapis.com/v0/b/horizon-millwork.appspot.com/o/homepage%2Flogo%2FHMS-logo.png?alt=media&token=bcf7039d-e48d-4906-a226-71a04f330029" alt="Horizon Millworks Logo" />
              </Link> */}
            </div>

            <div id="primary-menu-trigger" >
              <svg className="svg-trigger" viewBox="0 0 100 100" onClick={() => setShow(!show)}>
                <path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path>
                <path d="m 30,50 h 40"></path>
                <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path>
              </svg>
            </div>

            <nav className="primary-menu">
              <ul className="menu-container">
                <li className="menu-item">
                  <Link to="/" className="menu-link">
                    <div>Home</div>
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/products" className="menu-link">
                    <div>Products</div>
                  </Link>
                </li>
                <li className="menu-item mega-menu">
                  <Link to="/inspiration" className="menu-link">
                    <div>Inspiration</div>
                  </Link>
                </li>
                <li className="menu-item mega-menu">
                  <Link to="/resources" className="menu-link">
                    <div>Resources</div>
                  </Link>
                </li>
                <li className="menu-item mega-menu">
                  <Link to="/about" className="menu-link">
                    <div>About Us</div>
                  </Link>
                </li>
                <li className="menu-item mega-menu">
                  <Link to="/contact" className="menu-link">
                    <div>Contact</div>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className={`${show ? 'show' : 'hidden'}`} >
        <div className="nav-links" >
          <div className="links">
            <Link to="/" className="link" >
              Home
            </Link>
          </div>
          <div className="links">
            <Link to="/products" className="link" >
              Products
            </Link>
          </div>
          <div className="links">
            <Link to="/inspiration" className="link" >
              Inspiration
            </Link>
          </div>
          <div className="links">
            <Link to="/resources" className="link" >
              Resources
            </Link>
          </div>
          <div className="links">
            <Link to="/about" className="link" >
              About Us
            </Link>
          </div>
          <div className="links">
            <Link to="/contact " className="link" >
              Contact
            </Link>
          </div>
        </div>
      </div>
      <div className="header-wrap-clone"></div>
    </header>
  );
}
