import { Link } from 'react-router-dom';
import { ProductModel } from '../models/Product';

interface ProductCardProps {
    product: ProductModel,
    collectionId: string,
}
export function ProductCard({product,collectionId}:ProductCardProps) {

return						<div className="product col-lg-3 col-md-4 col-sm-6 col-12">
							<div className="grid-inner">
								<div className="product-image">
									<Link to={`/products/${collectionId}/${product.id}`}><img src={product.thumbnailUrl} alt={`Thumbnail for ${product.name}`}  /></Link>
								</div>
								<div className="product-desc">
									<div className="product-title"><h3><Link to={`/products/${collectionId}/${product.id}`}>{product.name}</Link></h3></div>
									<div className="product-title"><h3>{product.description}</h3></div>
								</div>
							</div>
						</div>



}
