import {Collection, CollectionWithProducts} from "./models/Collection";
import {firestore} from "./config/firebaseConfig";
import {collection,doc, getDoc, getDocs} from "firebase/firestore";
import { ProductData, ProductModel} from "./models/Product";
import { Metadata} from "./models/Metadata";
import {Inspiration} from "./models/Inspiration";
import {Specification} from "./models/Specification";

export async function getProductCollections(): Promise<Collection[]> {
  const productCollection = collection(firestore, 'collection');
  const querySnap = await getDocs(productCollection);
  return querySnap.docs.map((doc) => {
    const data = doc.data() as Collection;
    const { id } = doc;

    return {
      ...data,
      id,
    };
  });
}

export async function getCollectionWithProduct(collectionId: string): Promise<CollectionWithProducts> {
  const collectionDoc = await getDoc(doc(collection(firestore, 'collection'), collectionId));

  const { id } = collectionDoc;
  const data = collectionDoc.data() as {name: string};

  const productCollection: Collection = {
    id,
    ...data,
  };

  const productsSnap = await getDocs(collection(firestore, `collection/${id}/products`));

  const products : any = [];

  for (const doc of productsSnap.docs) {
    const prodId = doc.id;
    const prodData = doc.data() as {name: string;description: string; thumbnailUrl: string;headerUrl: string;};


    products.push({
      id: prodId,
      ...prodData,
    });
  }

  return {
    products,
    ...productCollection,
  };
}

export async function getAllProducts() {
  const collections = await getProductCollections();
  const promises = collections.map((e) => getCollectionWithProduct(e.id));

  let products:ProductModel[] = [];
  (await Promise.all(promises)).forEach((e) => {
    products = [
      ...products,
      ...e.products,
    ];
  });
  console.log(products);

  return products;
}

export async function getProductWithThumbnail(collectionId: string, productId: string):Promise<ProductModel> {
  const fdoc = await getDoc(doc(collection(firestore, `collection/${collectionId}/products`), productId));

  const prodId = fdoc.id;
  const prodData = fdoc.data() as {headerUrl: string; thumbnailUrl: string; name: string;description: string};

  return ({
    id: prodId,
    ...prodData,
  });
}

export async function getSpecificationsForProduct(collectionId: string, productId: string):Promise<Specification[]> {
  return (await getDocs(collection(firestore, `collection/${collectionId}/products/${productId}/specifications`))).docs.map((doc) => {
    const specId = doc.id;
    const specData = doc.data() as {description1: string ; description2: string};

    return {
      id: specId,
      ...specData,
    };
  });
}


export async function getMetadataWithImagesForProduct(collectionId: string, productId: string):Promise<Metadata[]> {
  const metadataSnap = (await getDocs(collection(firestore, `collection/${collectionId}/products/${productId}/metadata`)));

  return Promise.all( metadataSnap.docs.map((e) => {
    const { id } = e;
    const data = e.data() as {description: string; footerUrl: string; imgUrl: string};

    return {
      id,
      ...data,
    };
  }));
}

export async function getProductData(collectionId: string, productId: string):Promise<ProductData> {
  const specificationsPromise = getSpecificationsForProduct(collectionId, productId);
  const metadataPromise = getMetadataWithImagesForProduct(collectionId, productId);

  const [specifications, metadata] = await Promise.all([specificationsPromise, metadataPromise]);
  return {
    specifications,
    metadata,
  };
}

export async function getInspirations(): Promise<Inspiration[]> {
    const inspSnap = await getDocs(collection(firestore, 'inspiration'));

    return inspSnap.docs.map(inspS => {
        const id = inspS.id
        const data = inspS.data() as {description: string; images: string[],title: string};

        return {
            id, 
            ...data
        }
    })
}


export async function getInspirationWIthId(inspId: string): Promise<Inspiration> {
    const inspS = await getDoc(doc(collection(firestore, 'inspiration'),inspId));

        const id = inspS.id
        const data = inspS.data() as {description: string; images: string[],title: string};

        return {
            id, 
            ...data
        }
}
