import GAScript from "./GAScript";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import AboutUs from "./routes/Aboutus";
import ContactUs from "./routes/ContactUs";
import { HomePage } from "./routes/HomePage";
import { Inspiration } from "./routes/Inspiration";
import { ProductCollection } from "./routes/ProductCollection";
import { ProductDetailPage } from "./routes/ProductDetailPage";
import { ProductsPage } from "./routes/ProductsPage";
import { Resources } from "./routes/Resources";
import SignalProduct from './routes/SignalProduct'
import { SingleInspiration } from "./routes/SingleInspiration";

function App() {
  return (
    <div className="w-100">
      <GAScript />
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="products" element={<ProductsPage />}>
            <Route path=":collectionId" element={<ProductCollection />} />
          </Route>
          <Route path="products/:collectionId/:productId" element={<ProductDetailPage />} />
          <Route path="inspiration" element={<Inspiration />} />
          <Route path="product/:productId" element={<SignalProduct />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="inspiration/:inspirationId" element={<SingleInspiration />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
