import React from "react";
import "./AboutUs.css";
export default function AboutUs() {

  return (
    <div>
      <>
        <section>
          <div className="top wow bg">
            <div className="container">
              <div className="top_text"></div>
            </div>
          </div>
        </section>

        <section>
          <div className="container our-brand wow">
            <h2 className="fancy-heading">
              Our <br /> Brand{" "}
            </h2>

            <div className="col-lg-12 col-md-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 left-section">
                  <div className="our-brand_content">
                    <div className="our-brand_caption">
                      <h3 className="huge-heading" data-rendered="true">
                        <span className="text">
                          Full Turnkey Solution
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 ">
                  <div className="our-brand_paragraph">
                    Horizon Millworks specializes in the design, manufacture, and installation of custom woodwork and millwork for residential and commercial properties. Our team of experienced craftsmen use high-quality materials and advanced techniques to create beautiful and functional millwork solutions. From custom cabinetry and built-ins to doors, windows, and trim, we offer a wide range of services to enhance the aesthetic and value of your property. Our dedication to excellence and customer satisfaction sets us apart and makes us the premier choice for all your millwork needs.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="growth">
            <div className="paralexing ">
              <div className="paralexing_container">
                <div className="paralexing_content ">
                  <div className="loaded-bg" style={{ width: "100%" }} />
                  <div className="growth_content   ">
                    <div className="growth_heading">Trusted Partner</div>
                    <div className="growth_p ">
                      We are a trusted partner in the industry to be able to deliver on time and within budget. We provide comprehensive design and manufacturing services for custom millwork. We use high-quality materials and craftsmanship. We have a strong track record of satisfied customers and successful projects.
                    </div>
                  </div>
                </div>
                <div
                  className="paralexing_image "
                  style={{ transform: "translate3d(0px, 11.6342px, 0px)" }}
                >
                  <div className="growth_image" />
                </div>
              </div>
            </div>
          </div>
        </section>

     

        {/* <section className=" container">
          <div className="carts">
            <div
              className="carts_item wow"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="carts_heading" />
              <div className="carts_h2">Employees</div>
              <div className="carts_p">
                Our growing workforce is a clear indication of the company’s
                size and influence within the trade.
              </div>
            </div>
            <div
              className="carts_item wow"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="carts_heading" />
              <div className="carts_h2">Facility</div>
              <div className="carts_p">
                We are extremely excited about our new home in Newark. The new
                facility is equipped to handle the growth of the future.
              </div>
            </div>
            <div
              className="carts_item wow"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="carts_heading" />
              <div className="carts_h2">Horizon MillworksDealers</div>
              <div className="carts_p">
                With a presence on a national scale, it’s safe to say that
                Horizon Millworksis a powerful and leading corporation in the cabinetry
                world.
              </div>
            </div>
            <div
              className="carts_item wow"
              style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
              <div className="carts_heading" />
              <div className="carts_h2">Production</div>
              <div className="carts_p">
                With an awe-inspiring scale of thousands of cabinets shipped out
                daily, it is evident that demand for Horizon Millworksis certainly not
                lacking.
              </div>
            </div>
          </div>
        </section> */}

        <section>
   <div className="quality-check">
  <div className="quality-check_heading " >
    <div className="quality-heading quality-heading--dark mb-4">The icon <br /> of quality</div>
  </div>
  <div className="container mt-4">
    <div className="quality-check_wording wow">
      <div className="quality-check_h4">A New Standard of Quality</div>
      <div className="quality-check_columns">
        <span>Our goal is to create a kitchen experience that is both visually impressive and built with the highest quality materials. To achieve this, we carefully select only the finest raw materials and pay attention to every detail of construction. This ensures consistent quality and a uniform appearance in our cabinets. We believe that this attention to detail results in a product that is solid and reliable.</span>
        <span />
      </div>
    </div>
  </div>
 
  <div className="quality-check_image wow"/>
  <div className="quality-check_bgText">Horizon</div>
</div>


        </section>
      </>
    </div>
  );
}
