import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {ProductCard} from "../components/ProductCard";
import {useStore} from "../stores";

export function ProductCollection() {
    const {collectionId} = useParams();
  const loadCollectionWithProduct = useStore((s) => s.loadCollectionWithProduct);
  const isLoadingCurrentCollection = useStore((s) => s.isLoadingCurrentCollection);
  const loadAllProducts = useStore((s) => s.loadAllProducts);
  const currentCollection = useStore((s) => s.currentCollection);

  useEffect(() => {
      if(collectionId === 'all') {
          loadAllProducts()
      } else if (collectionId) {
          loadCollectionWithProduct(collectionId!)
      }
  },[collectionId,loadAllProducts,loadCollectionWithProduct])

  return <>


                <div id="shop" className="shop row grid-container gutter-30">
                    { isLoadingCurrentCollection ? <p>Loading...</p> :
                        currentCollection!.products.map((e,idx) => {
                            return <ProductCard key={idx} collectionId={currentCollection!.id} product={e} />
                        })
                    }
                </div>

                </>
}
