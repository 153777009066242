import React, { useEffect } from "react";
import "./ProductsPage.css";

import useFetchResourceData from "../hooks/useFetchResourcesData";

export function Resources() {

    const allProducts = useFetchResourceData();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });

    return (
        <section id="content">
            <div className="content-wrap">
                <div className="container">
                    <h1 className="heading">
                        RESOURCES
                    </h1>

                    <section className="product-area product-grid-list-area mt-4">
                        <div className="container">
                            <div className="col-md-12 col-lg-12 col-sm-6">
                                <div className="product-body-wrap">
                                    <div className="tab-content" id="nav-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="nav-grid"
                                            role="tabpanel"
                                            aria-labelledby="nav-grid-tab"
                                        >
                                            <div className="row ">

                                            </div>
                                            {<div className="row ">
                                                {allProducts.map((product, index: number) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="col-md-3 door-card center"
                                                        >
                                                            <a target="_blank" rel="noreferrer" href={product.link} >
                                                                {" "}
                                                                <img
                                                                    className="w-100"
                                                                    alt="This is the product"
                                                                    src={product.imageSrc?.toString()}
                                                                />
                                                            </a>
                                                            <div className="door-card_title">
                                                                {" "}
                                                                <span className="series">{product.name}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    );
}