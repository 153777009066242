import React from 'react';

import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../config/firebaseConfig';

function useFetchBannerData() {
  const [sliderDocs, setSliderDocs] = React.useState<any>([]);

  React.useEffect(() => {
    const collectionRef = collection(firestore, 'homepage', 'q1wUduhF5smn9NJAaRZw', 'slider');
    getDocs(collectionRef)
      .then((qs) => setSliderDocs(
        qs.docs.map((d) => d.data())
      ))
      .catch((e) => console.log(e));
  }, []);

  return sliderDocs;
}

export default useFetchBannerData;