import React, { useEffect } from "react";
import "./ContactUs.css";
export default function ContactUs() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  })

  return (
    <div>
      <>
        <section>
          <div className="top wow bg-main">
            <div className="container">
              <div className="top_text"></div>
            </div>
          </div>
        </section>

        <section>
          <div
            className="contact_other wow"
            style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
          >
            <h1 className="fancy-heading">
              Contact
              <br />
              Us
            </h1>
            <form
              target="_blank"
              className="jotform-form rendered"
              action="https://submit.jotform.com/submit/222594983310156/"
              method="post"
              name="form_222594983310156"
              id="222594983310156"
              accept-charset="utf-8"
              autoComplete="on"
            >
              <p className="form-page">
                Thank you for your interest in partnering with Horizon Millwork Solutions.
                Please leave your information below and one of our specialized representatives
                will reach out to you and assist you with your project needs.
              </p>
              <section className="form-section">
                <div className="form-section_fields-container">
                  <div className="form-field form-field--text">
                    <label className="label " htmlFor="name">
                      Name
                    </label>
                    {/* <input
                      type="text"
                      className="form-field-input"
                      id="name"
                      name="Name"
                      autoComplete="name"
                      required
                    /> */}

                    <input
                      type="text"
                      id="input_1"
                      name="q1_name1"
                      data-type="input-textbox"
                      className="form-textbox validate[required] form-field-input"
                      data-defaultvalue=""
                      placeholder=" "
                      data-component="textbox"
                      aria-labelledby="label_1"
                      required
                    />
                  </div>
                  <div className="form-field form-field--text">
                    <label className="label " htmlFor="email">
                      Email
                    </label>
                    {/* <input
                      type="email"
                      className="form-field-input"
                      id="email"
                      required
                    /> */}
                    <input
                      type="text"
                      id="input_3"
                      name="q3_email"
                      data-type="input-textbox"
                      className="form-textbox validate[required, Email] form-field-input"
                      data-defaultvalue=""
                      placeholder=" "
                      data-component="textbox"
                      aria-labelledby="label_3"
                      required
                    />
                  </div>
                  <div className="form-field form-field--text">
                    <label className="label " htmlFor="phone">
                      Phone
                    </label>
                    {/* <input
                      type="text"
                      className="form-field-input"
                      id="phone"
                      required
                    /> */}
                    <input
                      type="tel"
                      id="input_10_phone"
                      name="q10_phoneNumber[phone]"
                      className="form-textbox validate[required] form-field-input"
                      data-defaultvalue=""
                      autoComplete="section-input_10 tel-local"
                      data-component="phone"
                      aria-labelledby="label_10 sublabel_10_phone"
                      required
                    />
                  </div>
                  <div className="form-field form-field--text">
                    <label className="label " htmlFor="website">
                      Zip Code
                    </label>
                    {/* <input
                      type="text"
                      className="form-field-input"
                      name="Zip"
                      required
                    /> */}

                    <input
                      type="tel"
                      id="input_10_area"
                      name="q10_phoneNumber[area]"
                      className="form-textbox validate[required] form-field-input"
                      data-defaultvalue=""
                      autoComplete="section-input_10 tel-area-code"
                      data-component="areaCode"
                      aria-labelledby="label_10 sublabel_10_area"
                      required
                    />
                  </div>

                  <div className="form-field form-field--text">
                    <label className="label " htmlFor="website">
                      Business Name
                    </label>
                    {/* <input
                      type="text"
                      className="form-field-input"
                      name="Zip"
                      required
                    /> */}
                    <input
                      type="text"
                      id="input_9"
                      name="q9_businessName"
                      data-type="input-textbox"
                      className="form-textbox validate[required] form-field-input"
                      data-defaultvalue=""
                      data-component="textbox"
                      aria-labelledby="label_9"
                      required
                    />
                  </div>

                  <div className="form-field form-field--text">
                    <label className="label " htmlFor="website">
                      Business Hours
                    </label>
                    {/* <input
                      type="text"
                      className="form-field-input"
                      name="Zip"
                      required
                    /> */}

                    <input
                      type="text"
                      id="input_11"
                      name="q11_businessDayshours"
                      data-type="input-textbox"
                      className="form-textbox validate[required] form-field-input"
                      data-defaultvalue=""
                      data-component="textbox"
                      aria-labelledby="label_11"
                      required
                    />
                  </div>

                  <div className="form-textarea ">
                    <label className="label ">Message</label>
                    <textarea
                      className="form-field-input"
                      name="Message"
                      cols={50}
                      rows={5}
                    />
                  </div>
                </div>

                <div className="d-grid gap-2 col-12 mx-auto">
                  {/* <button className="btnn btn-primary" type="button">
                    Submit
                  </button> */}

                  <button
                    id="input_7"
                    type="submit"
                    className="form-submit-button submit-button jf-form-buttons jsTest-submitField btnn btn-primary"
                    data-component="button"
                    data-content=""
                  >
                    Submit{" "}
                  </button>
                </div>
              </section>
            </form>

            <div className="hours">
              <div className="fancy-heading">
                Hours of
                <br />
                Operation
              </div>
              <div className="hours">
                Monday-Friday 8-5 &nbsp;&nbsp; (201) 432.5667
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
}
